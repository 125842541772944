import {
    landingPageVideo1FR,
    landingPageVideo2FR,
    landingPageVideo1FRWebP,
    landingPageVideo2FRWebP,
    farmdokENMockAppleMobile,
    farmdokFRMockAppleMobile,
    farmdokENMockAppleMobileWebP,
    farmdokFRMockAppleMobileWebP,
} from 'assets/images';
import {
    Import_XML_Instructions_FR,
    Terms_and_conditions_EN,
    Terms_and_conditions_FR,
    Privacy_Notice_EN,
    Privacy_Notice_FR,
    NutriGuide_user_manual_EN_LAT,
} from 'assets/pdf';
import PhoneFR from 'components/Icons/phones/PhoneFR';

// MARK: - LocaleDependentBehaviours

export const en_FR = {
    borealis_lat_localization: '/en/fr/',
    farmdok_mock_apple_mobile: farmdokENMockAppleMobile,
    farmdok_mock_apple_mobile_webp: farmdokENMockAppleMobileWebP,
    terms_conditions_pdf: Terms_and_conditions_EN,
    privacy_notice_pdf: Privacy_Notice_EN,
    number_locales_code: 'en-FR',
    user_manual_pdf: NutriGuide_user_manual_EN_LAT,
};

export const fr_FR = {
    borealis_lat_localization: '/fr/fr/',
    farmdok_mock_apple_mobile: farmdokFRMockAppleMobile,
    farmdok_mock_apple_mobile_webp: farmdokFRMockAppleMobileWebP,
    terms_conditions_pdf: Terms_and_conditions_FR,
    privacy_notice_pdf: Privacy_Notice_FR,
    number_locales_code: 'fr-FR',
    xml_upload_instructions_pdf: Import_XML_Instructions_FR,
    user_manual_pdf: NutriGuide_user_manual_EN_LAT,
};

// MARK: - Behaviours

export const FR = {
    is_letters_available_in_postalcode: false,
    intro_youtube_video_id: '-esOcIcfUXA',
    landing_page_top_video_id: 'qcjKjdkmyI8',
    postal_code_length: 5,
    grass: true,
    n_mineralization: true,
    cover_crop_details: true,
    fertilizer_period: true,
    fertilizer_lasttime: true,
    soil_type: true,
    work_your_soil: true,
    periodic_el_info: true,
    soil_heaviness: false,
    soil_organic_matter_level: false,
    soil_analysis: false,
    applied_element_year: '',
    show_applied_element_year: true,
    soil_depth_id: 1,
    add_product: true,
    my_plots_functionalities: true,
    has_options_for_cover_crop_details: false,
    has_cover_crop_question: false,
    upload_xml: true,
    get_plots_from_3rd_party: false,
    can_select_postal_code_and_city_for_entire_farm_plots: false,
    can_use_map_and_postal_code_for_city_selection: true,
    crop_rotation: true,
    farmer_can_change_product: true,
    available_crops_id: [1, 2, 3, 4, 10, 11, 5, 7, 9],
    all_crops_presented: false, // Option to present all crop Icons at landing page
    only_for_bavaria_region: false,
    only_long_flow_supported: true,
    modified_long_flow_supported: true,
    available_crops_v3: [
        {
        name: 'sugar_beet',
        logoId: 9,
        },
        {
        name: 'winter_hard_wheat',
        logoId: 2,
        },
        {
        name: 'spring_durum_wheat',
        logoId: 1,
        },
        {
        name: 'winter_durum_wheat',
        logoId: 2,
        },
        {
        name: 'spring_soft_wheat',
        logoId: 1,
        },
        {
        name: 'winter_soft_wheat',
        logoId: 2,
        },
        {
        name: 'spring_oilseed_rape',
        logoId: 3,
        },
        {
        name: 'winter_oilseed_rape',
        logoId: 4,
        },
        {
        name: 'corn_grain',
        logoId: 5,
        },
        {
        name: 'spring_barley',
        logoId: 1,
        },
        {
        name: 'winter_barley',
        logoId: 2,
        },
        {
        name: 'sunflower',
        logoId: 7,
        },
    ],
    reseller_farmer_video: false,
    has_farmDok_slide: false,
    fachbeirat_logo: false,
    phone: PhoneFR,
    product_category_filter: true,
    spreader_links: {
        sulky: 'http://fertitest.sulky-burel.com/FertiKli/?lng=fr',
        amazone: 'http://www.amazone.fr/amaconnect.asp',
        kuhn: 'https://streutabellen.rauch-community.de/kuhntabelle/?lang=33',
        bogballe: 'http://www.bogballe-charts.com/spreader.asp?S=1',
        kverneland: 'https://www.kvernelandspreadingcharts.com',
        vicon: 'https://www.viconspreadingcharts.com/vicon/home/',
    },
    map_options: {
        defaultCenter: {
        lat: 46.4,
        lng: 2.43,
        },
        zoom: 5,
    },
    xml_files_download_link: 'https://www.telepac.agriculture.gouv.fr/telepac/auth/accueil.action',
    fertilizer_needs: true,
    my_applications: true,
    landing_page_video_1: landingPageVideo1FR,
    landing_page_video_1_webp: landingPageVideo1FRWebP,
    landing_page_video_2: landingPageVideo2FR,
    landing_page_video_2_webp: landingPageVideo2FRWebP,
    landing_page_yt_video_1: 'eqzEm65ld9Y',
    landing_page_yt_video_2: '4j2Kj130RG8',
    supported_file_types: 'text/xml',
    refer_a_farmer_functionality: false,
    promotion_image: null,
    empty_soil: {
        soilFactorP: 1,
        soilFactorK: 1,
        nMineralization: 0,
        nMin: 50,
    },
    has_n_min: false,
    soil_class_classification_presented: false,
    application_map_functionality: true,
    application_map_functionality_not_working: false,
    shouldShowChangeFormulae_in_all_steps: false,
    has_nutrizone: false,
    nutrizone_brand_video_id: null,
    nutrizone_testimonial_video_id: null,
    get_postal_code_on_xml_import: false,
    tutorial_videos_link: '',
    nmineralization_humus_option: false,
    has_organic_fertilization_in_the_previous_year_option: false,
    has_red_zone: false,
    only_soil_classification_letters_presented: false,
    has_no_options_for_nMineralization: false,
    eAMA_text: true,
    click_here_text: true,
    want_to_set_N_upper_limit: false,
    try_nutriguide_button_visibility: true,
};
