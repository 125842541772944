import XMLInstructions from './Anleitung_Export_Import_Flaechen_LAT.pdf';
import Import_XML_Instructions_FR from './Import_XML_Instructions_FR.pdf';
import Terms_and_conditions_DE from './General_Terms_Conditions_NutriGuide_LAT_Nitrogen_2023_DE.pdf';
import Terms_and_conditions_EN from './General_Terms_Conditions_NutriGuide_LAT_Nitrogen_2023_EN.pdf';
import Terms_and_conditions_FR from './General_Terms_Conditions_NutriGuide_LAT_Nitrogen_2023_FR.pdf';
import Terms_and_conditions_BG from './General_Terms_Conditions_NutriGuide_LAT_Nitrogen_2023_BG.pdf';
import Privacy_Notice_DE from './Privacy_Notice_NutriGuide_DE_19.10.2023.pdf';
import Privacy_Notice_EN from './Privacy_Notice_NutriGuide_EN.pdf';
import Privacy_Notice_FR from './Privacy_Notice_NutriGuide_FR_19.10.2023.pdf';
import Privacy_Notice_BG from './Privacy_Notice_NutriGuide_BG_19.10.2023.pdf';
import Import_Plot_Instructions_RO from './Description_to_download_the_GPX_file_RO_RO_LAT.pdf';
import Import_Plot_Instructions_HU from './Import_plots_XLS_HU_HU_LAT.pdf';
import Terms_and_conditions_HU from './General_Terms_Conditions_NutriGuide_LAT_Nitrogen_2023_HU.pdf';
import Terms_and_conditions_RO from './General_Terms_Conditions_NutriGuide_LAT_Nitrogen_2023_RO.pdf';
import Privacy_Notice_RO from './Privacy_Notice_NutriGuide_RO_19.10.2023.pdf';
import Privacy_Notice_HU from './Privacy_Notice_NutriGuide_HU_19.10.2023.pdf';
import EAMA_Instructions from './EAMA_Instructions.pdf';
import Nutriguide_User_Manual_DE from './NutriGuide_user_manual_DE_Düngekarten.pdf';
import Nutriguide_User_Manual_HU from './NutriGuide_user_manual_HU_formatted.pdf';
import Nutriguide_User_Manual_RO from './NutriGuide_user_manual_RO_formatted.pdf';
import Nutriguide_User_Manual_BG from './NutriGuide_user_manual_BG_formatted.pdf';
import NutriGuide_user_manual_BG_LAT from './NutriGuide_user_manual_BG_LAT.pdf';
import NutriGuide_user_manual_DE_LAT from './NutriGuide_user_manual_DE_LAT.pdf';
import NutriGuide_user_manual_EN_LAT from './NutriGuide_user_manual_EN_LAT.pdf';
import NutriGuide_user_manual_HU_LAT from './NutriGuide_user_manual_HU_LAT.pdf';
import NutriGuide_user_manual_RO_LAT from './NutriGuide_user_manual_RO_LAT.pdf';

export {
  XMLInstructions,
  Import_XML_Instructions_FR,
  Terms_and_conditions_DE,
  Terms_and_conditions_EN,
  Terms_and_conditions_FR,
  Terms_and_conditions_BG,
  Privacy_Notice_DE,
  Privacy_Notice_EN,
  Privacy_Notice_FR,
  Privacy_Notice_BG,
  Import_Plot_Instructions_RO,
  Import_Plot_Instructions_HU,
  Terms_and_conditions_HU,
  Terms_and_conditions_RO,
  Privacy_Notice_RO,
  Privacy_Notice_HU,
  EAMA_Instructions,
  Nutriguide_User_Manual_DE,
  Nutriguide_User_Manual_HU,
  Nutriguide_User_Manual_RO,
  Nutriguide_User_Manual_BG,
  NutriGuide_user_manual_BG_LAT,
  NutriGuide_user_manual_DE_LAT,
  NutriGuide_user_manual_EN_LAT,
  NutriGuide_user_manual_HU_LAT,
  NutriGuide_user_manual_RO_LAT,
};
