/* eslint-disable no-await-in-loop */
import {
  UPDATE_USER,
  GET_TERMS_LOADING,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILED,
  GET_ORGANIZATION_LOADING,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILED,
  POST_ORGANIC_FERTILIZER_LOADING,
  POST_ORGANIC_FERTILIZER_SUCCESS,
  POST_ORGANIC_FERTILIZER_FAILED,
  PUT_ORGANIC_FERTILIZER_LOADING,
  PUT_ORGANIC_FERTILIZER_SUCCESS,
  PUT_ORGANIC_FERTILIZER_FAILED,
  DELETE_ORGANIC_FERTILIZER_LOADING,
  DELETE_ORGANIC_FERTILIZER_SUCCESS,
  DELETE_ORGANIC_FERTILIZER_FAILED,
  GET_APPLICATION_STEPS_LOADING,
  GET_APPLICATION_STEPS_SUCCESS,
  GET_APPLICATION_STEPS_FAILED,
  GET_CITY_LIST_LOADING,
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_FAILED,
  POST_USER_LOADING,
  POST_USER_SUCCESS,
  POST_USER_FAILED,
  PUT_USER_LOADING,
  PUT_USER_SUCCESS,
  PUT_USER_FAILED,
  GET_USER_LOADING,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  POST_PLAN_LOADING,
  POST_PLAN_SUCCESS,
  POST_PLAN_FAILED,
  POST_PLAN_BULK_LOADING,
  POST_PLAN_BULK_SUCCESS,
  POST_PLAN_BULK_FAILED,
  PUT_PLAN_LOADING,
  PUT_PLAN_SUCCESS,
  PUT_PLAN_FAILED,
  DELETE_PLAN_LOADING,
  DELETE_PLAN_SUCCESS_NG4,
  DELETE_PLAN_FAILED,
  GET_MY_PLANS_LOADING,
  GET_MY_PLANS_NG4_SUCCESS,
  GET_MY_PLANS_FAILED,
  MERGE_NG4_PLAN,
  GET_CROP_LIST_LOADING,
  GET_CROP_LIST_SUCCESS,
  GET_CROP_LIST_FAILED,
  GET_ORGANIC_FERTILIZER_LIST_LOADING,
  GET_ORGANIC_FERTILIZER_LIST_SUCCESS,
  GET_ORGANIC_FERTILIZER_LIST_FAILED,
  GET_SOIL_LIST_LOADING,
  GET_SOIL_LIST_SUCCESS,
  GET_SOIL_LIST_FAILED,
  GET_SOIL_COMPOSITION_LOADING,
  GET_SOIL_COMPOSITION_SUCCESS,
  GET_SOIL_COMPOSITION_FAILED,
  GET_PRODUCT_SELECT_LOADING,
  GET_PRODUCT_SELECT_SUCCESS,
  GET_PRODUCT_SELECT_FAILED,
  GET_PRODUCT_LIST_LOADING,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  GET_PRODUCT_LOADING,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILED,
  POST_PRODUCT_LOADING,
  POST_PRODUCT_SUCCESS,
  POST_PRODUCT_FAILED,
  PUT_PRODUCT_LOADING,
  PUT_PRODUCT_SUCCESS,
  PUT_PRODUCT_FAILED,
  DELETE_PRODUCT_LOADING,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED,
  MY_PLOTS_LOADING,
  MY_PLOTS_SUCCESS,
  MY_PLOTS_FAILED,
  MERGE_PLOT,
  REMOVE_TEMP_PLOTS,
  DELETE_MY_PLOT_LOADING,
  DELETE_MY_PLOT_SUCCESS,
  DELETE_MY_PLOT_FAILED,
  POST_MY_PLOT_LOADING,
  POST_MY_PLOT_SUCCESS,
  POST_MY_PLOT_FAILED,
  POST_PLOT_LOADING,
  POST_PLOT_SUCCESS,
  POST_PLOT_FAILED,
  MY_CROP_ROTATIONS_LOADING,
  MY_CROP_ROTATIONS_FAILED,
  MY_CROP_ROTATIONS_SUCCESS_NG4,
  DELETE_MY_CROP_ROTATION_LOADING,
  DELETE_MY_CROP_ROTATION_SUCCESS,
  DELETE_MY_CROP_ROTATION_FAILED,
  POST_MY_CROP_ROTATION_LOADING,
  POST_MY_CROP_ROTATION_FAILED,
  POST_MY_CROP_ROTATION_SUCCESS_NG4,
  RESET_MY_CROP_ROTATIONS,
  GET_REFERALS_LOADING,
  GET_REFERALS_SUCCESS,
  GET_REFERALS_FAILED,
  POST_REFERALS_LOADING,
  POST_REFERALS_SUCCESS,
  POST_REFERALS_FAILED,
  GET_TERRAZO_FIELDS_LOADING,
  GET_TERRAZO_FIELDS_SUCCESS,
  GET_TERRAZO_FIELDS_FAILED,
  POST_TERRAZO_FIELDS_LOADING,
  POST_TERRAZO_FIELDS_SUCCESS,
  POST_TERRAZO_FIELDS_FAILED,
  PATCH_TERRAZO_FIELDS_LOADING,
  PATCH_TERRAZO_FIELDS_SUCCESS,
  PATCH_TERRAZO_FIELDS_FAILED,
  DELETE_TERRAZO_FIELDS_LOADING,
  DELETE_TERRAZO_FIELDS_SUCCESS,
  DELETE_TERRAZO_FIELDS_FAILED,
  DELETE_TERRAZO_FIELDS_FOR_RESOURCE_LOADING,
  DELETE_TERRAZO_FIELDS_FOR_RESOURCE_SUCCESS,
  DELETE_TERRAZO_FIELDS_FOR_RESOURCE_FAILED,
  RESET_TERRAZO_FIELDS,
  DELETE_MY_PLOT_BULK_LOADING,
  DELETE_MY_PLOT_BULK_SUCCESS,
} from 'Redux/actions/types';
import { flattenSoilComposition, planFlowTypes } from 'Utils/PlanUtils';
import {
  getUpdatedPlansWithPlotNG4,
  getPlansMergedWithPlots,
} from 'Utils/MyPlansUtils';
import UserUtils from 'Utils/UserUtils';
import { getUrl, getRestResource, borealisPageUrl } from 'data/Config';
import { getToken, logout } from 'Utils/MSAL_AuthUtils';
import { store } from 'Redux/createStore';
import { updateUser } from 'Redux/actions/UserActions';
import { GTMEventTypes } from 'constants/index.js';
import { createVirtualPageView } from 'Utils/DataLayerUtils';
import LocalizationService from 'services/LocalizationService';

const AVERAGE_FERT_RATE_VALUE_ERROR =
  "Invalid params: ['averageFertilizerRate'] valid values ['25-80'].";

class ProcessError extends Error {
  constructor(message, id) {
    super(message);
    this.id = id;
  }
}

class ProcessQueue {
  static sharedInstance;

  static shared() {
    if (!this.sharedInstance) {
      this.sharedInstance = new ProcessQueue();
    }
    return this.sharedInstance;
  }

  constructor() {
    this.processes = [];
  }

  addProcess(process) {
    this.processes.push(process);
  }

  executeProcesses() {
    return new Promise(async (resolve) => {
      const successIds = [];
      const failureIds = [];
      while (this.processes.length > 0) {
        const process = this.processes.shift();
        try {
          const id = await process.start();
          successIds.push(id);
        } catch (error) {
          console.error(error);
          failureIds.push(error.id);
        }
      }
      resolve({ successIds, failureIds });
    });
  }
}

class Process {
  constructor(process, success) {
    this.process = process;
    this.success = success;
  }

  start() {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, resp } = await this.process();
        if (resp !== undefined && this.success !== undefined) {
          this.success(resp);
        }
        resolve(id);
      } catch (error) {
        reject(error);
      }
    });
  }
}

/* Generic Internal Web Methods */
const genericRequest = (url, options, successClosure, failureClosure) => {
  fetch(url, options)
    .then((response) => {
      switch (response.status) {
        case 200:
          if (response.headers.get('Content-Type') === 'application/json') {
            response.json().then((json) => successClosure(json, 200));
          } else {
            response.blob().then((imageBlob) => successClosure(imageBlob, 200));
          }
          break;
        case 204:
          successClosure(null, 204);
          break;
        case 207:
          response.json().then((json) => successClosure(json, 207));
          break;
        case 401:
          getToken().then((token) => {
            store.dispatch(updateUser({ id_token: token }));
            genericRequest(
              url,
              { ...options, ...{ headers: { id_token: token } } },
              successClosure,
              failureClosure,
            );
          });
          break;
        default:
          // if (response.status) failureClosure(response.status, response);
          response
            .json()
            .then((json) => {
              if (json.errorCode === 83) {
                logout();
              }
              failureClosure(response.status, json);
            })
            .catch(() => {
              failureClosure(response.status, response);
            });
      }
    })
    .catch(() => {
      failureClosure('timeout', { errorCode: 408 });
    });
};

export const webcallPromiseWrapper = (
  url,
  method,
  id_token,
  body,
  contentType,
  shouldStringify = true,
) =>
  new Promise((resolve, reject) => {
    const options = { method };
    if (body && shouldStringify) {
      const bodyWithConvertedLanguageCode =
        LocalizationService.getBodyWithConvertedLangaugeCode(body);
      options.body = shouldStringify
        ? JSON.stringify(bodyWithConvertedLanguageCode)
        : bodyWithConvertedLanguageCode;
    } else {
      options.body = body;
    }
    if (id_token !== undefined) {
      options.headers = { id_token };
    }
    if (contentType !== undefined) {
      options.headers = { ...options.headers, 'Content-Type': contentType };
    }
    const urlWithConvertedLanguageCode = LocalizationService.getUrlWithConvertedLanguageCode(url);
    genericRequest(
      urlWithConvertedLanguageCode,
      options,
      (json, statusCode) => resolve({ json, statusCode }),
      (statusCode, json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ statusCode, errorCode: json.errorCode, errorMessage: json.errorMessage }),
    );
  });

export const getWebServiceCallForBulkOperation =
  (id, ...webServiceArguments) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        const resp = await webcallPromiseWrapper(...webServiceArguments);
        resolve({ id, resp });
      } catch (error) {
        reject(new ProcessError(error, id));
      }
    });

/* WEB CALLS */
export const getTerms = (queryParams) => (dispatch) => {
  dispatch({ type: GET_TERMS_LOADING });
  const finalQueryParams = queryParams;

  if (queryParams.locale === 'en_UK') {
    finalQueryParams.locale = 'uk_UK';
  }

  const url = getUrl('getTerms', finalQueryParams);
  webcallPromiseWrapper(url, 'GET')
    .then((result) => dispatch({ type: GET_TERMS_SUCCESS, payload: result.json.paramsOut }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_TERMS_FAILED, payload: error });
    });
};

// TODO Check with Dries, I've added a 'nodata' failure response,
// maybe would be more consistent with a success but empty data
export const getOrganisation = (queryParams) => (dispatch) => {
  dispatch({ type: GET_ORGANIZATION_LOADING });
  const url = getUrl('getOrganisation', queryParams);
  webcallPromiseWrapper(url, 'GET')
    .then((result) => {
      if (!result.json.paramsOut || result.json.paramsOut.length === 0) {
        dispatch({ type: GET_ORGANIZATION_FAILED, payload: { errorCode: 404 } });
      } else {
        dispatch({ type: GET_ORGANIZATION_SUCCESS, payload: result.json.paramsOut });
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_ORGANIZATION_FAILED, payload: error });
    });
};

export const getApplicationSteps = (queryParams) => (dispatch) => {
  dispatch({ type: GET_APPLICATION_STEPS_LOADING });
  const url = getRestResource('/applicationsteps', queryParams);
  webcallPromiseWrapper(url, 'GET')
    .then((result) => dispatch({ type: GET_APPLICATION_STEPS_SUCCESS, payload: result.json }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_APPLICATION_STEPS_FAILED, payload: error });
    });
};

export const getCityListFromParam = (param) =>
  new Promise((resolve, reject) => {
    const url = getUrl('cityList', param);
    webcallPromiseWrapper(url, 'GET')
      .then((result) => resolve(result.json.paramsOut.cities))
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const getCityList = (queryParams) => (dispatch) => {
  dispatch({ type: GET_CITY_LIST_LOADING });
  getCityListFromParam(queryParams)
    .then((res) => dispatch({ type: GET_CITY_LIST_SUCCESS, payload: res }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_CITY_LIST_FAILED, payload: error });
    });
};

export const getCropList = (queryParams) => (dispatch) => {
  dispatch({ type: GET_CROP_LIST_LOADING });
  const { locale } = queryParams;
  const url = getUrl('genericCropList', { locale });

  webcallPromiseWrapper(url, 'GET')
    .then((result) => {
      dispatch({ type: GET_CROP_LIST_SUCCESS, payload: result.json.crops });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_CROP_LIST_FAILED, payload: error });
    });
};

export const getSoilList = (queryParams) => (dispatch) => {
  dispatch({ type: GET_SOIL_LIST_LOADING });
  const url = getUrl('soilList', queryParams);
  webcallPromiseWrapper(url, 'GET')
    .then((result) => {
      dispatch({ type: GET_SOIL_LIST_SUCCESS, payload: result.json.paramsOut });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_SOIL_LIST_FAILED, payload: error });
    });
};

// Review: Unusual case - directly updates the plan, kind of hard coding logic into a webservice
export const getSoilComposition = (queryParams) => (dispatch) => {
  dispatch({ type: GET_SOIL_COMPOSITION_LOADING });
  const url = getUrl('soilComposition', queryParams);
  webcallPromiseWrapper(url, 'GET')
    .then((result) =>
      dispatch({
        type: GET_SOIL_COMPOSITION_SUCCESS,
        payload: flattenSoilComposition(result.json.paramsOut),
      }),
    )
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_SOIL_COMPOSITION_FAILED, payload: error });
    });
};

// NG4 and also FR is now using the same endpoint
export const getOrganicFertilizerList = (queryParams) => (dispatch) => {
  dispatch({ type: GET_ORGANIC_FERTILIZER_LIST_LOADING });
  const { locale } = queryParams;
  const url = getUrl('genericOrganicFertilizer', { locale });
  const { id_token } = queryParams;

  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) => {
      dispatch({
        type: GET_ORGANIC_FERTILIZER_LIST_SUCCESS,
        payload: result.json.organicFertilizers,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_ORGANIC_FERTILIZER_LIST_FAILED, payload: error });
    });
};

export const postOrganicFertilizer =
  (id_token, locale, organicFertilizer, success) => (dispatch) => {
    dispatch({ type: POST_ORGANIC_FERTILIZER_LOADING });
    const url = getUrl('genericOrganicFertilizer', { locale });
    webcallPromiseWrapper(url, 'POST', id_token, organicFertilizer)
      .then((result) => {
        dispatch({ type: POST_ORGANIC_FERTILIZER_SUCCESS, payload: result.json });
        if (success) {
          success(result.json);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: POST_ORGANIC_FERTILIZER_FAILED, payload: error });
      });
  };

export const putOrganicFertilizer =
  (id_token, locale, organicFertilizer, success) => (dispatch) => {
    dispatch({ type: PUT_ORGANIC_FERTILIZER_LOADING });
    const url = getUrl('genericOrganicFertilizer', { locale }, `/${organicFertilizer.id}`);
    webcallPromiseWrapper(url, 'PUT', id_token, organicFertilizer)
      .then((result) => {
        dispatch({ type: PUT_ORGANIC_FERTILIZER_SUCCESS, payload: result.json });
        if (success) {
          success(result.json);
        }
      })
      .catch((error) => {
        dispatch({ type: PUT_ORGANIC_FERTILIZER_FAILED, payload: error });
      });
  };

export const deleteOrganicFertilizer =
  (id_token, locale, organicFertilizer, success) => (dispatch) => {
    dispatch({ type: DELETE_ORGANIC_FERTILIZER_LOADING });
    const url = getUrl('genericOrganicFertilizer', { locale }, `/${organicFertilizer.id}`);
    webcallPromiseWrapper(url, 'DELETE', id_token, organicFertilizer)
      .then(() => {
        dispatch({ type: DELETE_ORGANIC_FERTILIZER_SUCCESS, payload: organicFertilizer.id });
        if (success) {
          success();
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: DELETE_ORGANIC_FERTILIZER_FAILED, payload: error });
      });
  };

export const getProductSelectList = (route, id_token) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_SELECT_LOADING });
  const url = getRestResource(route);
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) =>
      dispatch({ type: GET_PRODUCT_SELECT_SUCCESS, payload: { data: result.json } }),
    )
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_PRODUCT_SELECT_FAILED, payload: error });
    });
};

export const getProductList = (id_token) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_LIST_LOADING });
  const url = getRestResource('/product');
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) => dispatch({ type: GET_PRODUCT_LIST_SUCCESS, payload: { data: result.json } }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_PRODUCT_LIST_FAILED, payload: error });
    });
};

export const getProduct = (id, id_token) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_LOADING });
  const url = getRestResource(`/product/${id}`);
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) => dispatch({ type: GET_PRODUCT_SUCCESS, payload: { data: result.json } }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_PRODUCT_FAILED, payload: error });
    });
};

/* USER methods */

// TODO could all be handled in SEND/GET user success
const handleGetUserSuccess = (dispatch, result, successClosure) => {
  const user = result.json.paramsOut;
  if (user) {
    dispatch({
      type: UPDATE_USER,
      payload: {
        ...UserUtils.convertBackendToFrontend(user),
        IN_DATABASE: true,
        isOnboarding: false,
      },
    });
    if (successClosure) {
      successClosure();
    }
  }
};

// REVIEW: A convenience method, should probaly find a better way to do this,
// I separated it from getUser because it dispatches different status messages
// which make it easier to keep the front end flow correct... no export on this method
const getUserAfterPost = (dispatch, id_token, successClosure) => {
  const url = getUrl('user');
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) => {
      handleGetUserSuccess(dispatch, result, successClosure);
      createVirtualPageView(GTMEventTypes.USER_REGISTERED);
      dispatch({ type: POST_USER_SUCCESS });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_USER_FAILED, payload: error });
    });
};

export const getUser = (dispatch, id_token) => {
  dispatch({ type: GET_USER_LOADING });
  const url = getUrl('user');
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) => {
      handleGetUserSuccess(dispatch, result);
      dispatch({ type: GET_USER_SUCCESS });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_USER_FAILED, payload: error });
    });
};

export const getUserToCheckNZLoggedInState = (id_token) => (dispatch) => {
  const url = getUrl('user');
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((result) => {
      handleGetUserSuccess(dispatch, result);
      dispatch({ type: GET_USER_SUCCESS });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_USER_FAILED, payload: error });
    });
};

// We do this in a 2 stage process to getting the user back afterwards will have extra fields
export const postUser = (user, id_token, successClosure) => (dispatch) => {
  dispatch({ type: POST_USER_LOADING });
  const output = UserUtils.convertFrontendToBackend(user);

  if (user.locale === 'en_UK') {
    output.languageCode = 'UK';
  }

  webcallPromiseWrapper(getUrl('user'), 'POST', id_token, output)
    .then(() => getUserAfterPost(dispatch, id_token, successClosure))
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_USER_FAILED, payload: error });
    });
};

export const putUser = (user, id_token) => (dispatch) => {
  dispatch({ type: PUT_USER_LOADING });
  webcallPromiseWrapper(getUrl('user'), 'PUT', id_token, user)
    .then(() => dispatch({ type: PUT_USER_SUCCESS, payload: user }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: PUT_USER_FAILED, payload: error });
    });
};

const getMyPlotsAction = (id_token) =>
  new Promise((resolve, reject) => {
    const url = getRestResource('/plot');
    webcallPromiseWrapper(url, 'GET', id_token)
      .then((res) => {
        resolve(res.json);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const getMyPlots = (id_token) => async (dispatch) => {
  dispatch({ type: MY_PLOTS_LOADING });
  try {
    const plots = await getMyPlotsAction(id_token);
    dispatch({
      type: MY_PLOTS_SUCCESS,
      payload: plots,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: MY_PLOTS_FAILED, payload: error });
  }
};

/* PLAN methods */
export const getPlanList = (lang, locale, role, id_token) => (dispatch) => {
  dispatch({ type: GET_MY_PLANS_LOADING });
  const url = getUrl('genericPlan', { locale });
  webcallPromiseWrapper(url, 'GET', id_token)
    .then(async (result) => {
      let plans = result.json;

      // Merging plots with plans by plot ID
      const plots = store.getState().myPlots;
      let plotList;
      if (plots && plots.data.length === 0) {
        plotList = await getMyPlotsAction(id_token);
        dispatch({
          type: MY_PLOTS_SUCCESS,
          payload: plotList,
        });
      }
      plans = getPlansMergedWithPlots(plans, plotList || plots.data);
      dispatch({ type: GET_MY_PLANS_NG4_SUCCESS, payload: { data: plans, role } });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_MY_PLANS_FAILED, payload: error });
    });
};

export const postPlan = (plan, id_token, lang, role) => (dispatch) => {
  dispatch({ type: POST_PLAN_LOADING });
  let url = getUrl('genericPlan');

  // Automatic plan is sent as a full plan and URL param contains that it is an autoplan
  if (plan.flow === planFlowTypes.automaticPlanning) {
    // eslint-disable-next-line no-param-reassign
    plan.flow = planFlowTypes.full;
    url += '&isAutoPlan=true';
  }

  webcallPromiseWrapper(url, 'POST', id_token, plan)
    .then((result) => {
      createVirtualPageView(GTMEventTypes.PLAN_CREATED);
        dispatch({ type: POST_PLAN_SUCCESS, payload: result });
        dispatch({ type: MERGE_NG4_PLAN, payload: { plans: [result.json], role } });
        dispatch({ type: REMOVE_TEMP_PLOTS });
        dispatch({ type: MERGE_PLOT, payload: result.json.plot });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_PLAN_FAILED, payload: error });
    });
};

// TODO:Error handling + pass 204s in to success
export const putPlan = (plan, id_token, lang, role) => (dispatch) => {
  dispatch({ type: PUT_PLAN_LOADING });
  const url = getUrl('genericPlan', null, `/${plan.id}`);
  webcallPromiseWrapper(url, 'PUT', id_token, plan)
    .then((result) => {
        dispatch({ type: PUT_PLAN_SUCCESS, payload: result });
        dispatch({ type: MERGE_NG4_PLAN, payload: { plans: [result.json], role } });
        dispatch({ type: REMOVE_TEMP_PLOTS });
        dispatch({ type: MERGE_PLOT, payload: result.json.plot });
        if (result.json.updatedCropRotations) {
          dispatch({ type: RESET_MY_CROP_ROTATIONS });
        }
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: PUT_PLAN_FAILED, payload: error });
    });
};

export const deletePlan = (planId, lang, locale, role, id_token, forced) => (dispatch) => {
  dispatch({ type: DELETE_PLAN_LOADING });
  let url = getUrl('genericPlan', { id: planId, locale }, `/${planId}`);
    if (forced) {
      url += '&forceDelete=true';
    }
  webcallPromiseWrapper(url, 'DELETE', id_token)
    .then(() => {
      dispatch({ type: DELETE_PLAN_SUCCESS_NG4, payload: { planId, lang, role } });
      dispatch({ type: RESET_TERRAZO_FIELDS });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: DELETE_PLAN_FAILED, payload: error });
    });
};

/* PRODUCT actions */
export const postProduct = (product, id_token) => (dispatch) => {
  dispatch({ type: POST_PRODUCT_LOADING });
  const url = getRestResource('/product');
  webcallPromiseWrapper(url, 'POST', id_token, product)
    .then((result) => dispatch({ type: POST_PRODUCT_SUCCESS, payload: result }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_PRODUCT_FAILED, payload: error });
    });
};

export const putProduct = (product, id_token, productId) => (dispatch) => {
  dispatch({ type: PUT_PRODUCT_LOADING });
  const url = getRestResource(`/product/${productId}`);
  webcallPromiseWrapper(url, 'PUT', id_token, product)
    .then((result) => dispatch({ type: PUT_PRODUCT_SUCCESS, payload: result }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: PUT_PRODUCT_FAILED, payload: error });
    });
};

export const deleteProduct = (id_token, productId) => (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_LOADING });
  const url = getRestResource(`/product/${productId}`);
  webcallPromiseWrapper(url, 'DELETE', id_token)
    .then((result) => dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: result }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: DELETE_PRODUCT_FAILED, payload: error });
    });
};

export const deletePlot = (id_token, plotId, deleActionSuccessClosure) => (dispatch) => {
  dispatch({ type: DELETE_MY_PLOT_LOADING });
  const url = getRestResource(`/plot/${plotId}`);
  webcallPromiseWrapper(url, 'DELETE', id_token)
    .then(() => {
      deleActionSuccessClosure(plotId);
      dispatch({
        type: DELETE_MY_PLOT_SUCCESS,
        payload: plotId,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: DELETE_MY_PLOT_FAILED, payload: error });
    });
};

export const uploadPlotsXml = (id_token, file, countryCode, locale, contentType) =>
  new Promise((resolve, reject) => {
  const url = `${getRestResource('/plot/')}&fields=id,name,size&locale=${locale}&isDataFileUpload=1`;

  webcallPromiseWrapper(
    url,
    'POST',
    id_token,
    file,
    contentType,
    contentType === 'application/json',
  )
    .then((res) => {
      if (res.statusCode === 207) {
        return resolve(res);
      }
      return resolve(res.json);
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
  });

export const getXMLFromEama = (id_token, credentials, countryCode) =>
  new Promise((resolve, reject) => {
    const url = `${getRestResource('/eama/farmdata/')}&countryCode=${countryCode}`;
    webcallPromiseWrapper(url, 'POST', id_token, credentials)
      .then((res) => resolve(res.json))
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const postPlots = (id_token, plots, countryCode, locale) => (dispatch) => {
  dispatch({ type: POST_MY_PLOT_LOADING });
  const url = `${getRestResource('/plot/')}&locale=${locale}`;

  webcallPromiseWrapper(url, 'PATCH', id_token, plots)
    .then((res) =>
      dispatch({
        type: POST_MY_PLOT_SUCCESS,
        payload: res.json instanceof Array ? res.json : [res.json],
      }),
    )
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_MY_PLOT_FAILED, payload: error });
    });
};

export const postPlot = (id_token, plot, countryCode, locale) => (dispatch) => {
  dispatch({ type: POST_PLOT_LOADING });

  const url = `${getRestResource('/plot/')}&locale=${locale}`;

  webcallPromiseWrapper(url, 'POST', id_token, plot, 'application/json')
    .then((res) => dispatch({ type: POST_PLOT_SUCCESS, payload: res.json }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_PLOT_FAILED, payload: error });
    });
};

export const patchPlot =
  (
    id_token,
    plot,
    lang,
    countryCode,
    locale,
    role,
    successClosure,
    forceCallSuccessClosure = false,
  ) =>
  (dispatch) => {
    dispatch({ type: POST_PLOT_LOADING });

    const url = `${getRestResource('/plot/')}&locale=${locale}`;

    webcallPromiseWrapper(url, 'PATCH', id_token, plot)
      .then((res) => {
        dispatch({ type: POST_PLOT_SUCCESS, payload: res.json });
        const updatedPlot = {
          id: res.json.id,
          name: res.json.name,
          size: res.json.size,
          city: res.json.city,
          postalCode: res.json.postalCode,
          comment: res.json.comment,
          date: res.json.date,
          coordinates: res.json.coordinates,
          farm: {
            id: res.json.farm.id,
            name: res.json.farm.name,
            farmerName: res.json.farm.farmerName,
            userId: res.json.farm.userId,
          },
        };

        if (res.json.soil) {
          updatedPlot.soil = { ...res.json.soil };
        }

        // Updating redux state with the updated plans (LONG flow)
        if (res.json.updatedPlans) {
          let updatedPlansWithPlot = [];
          updatedPlansWithPlot = getUpdatedPlansWithPlotNG4(res.json.updatedPlans, updatedPlot);
          dispatch({ type: MERGE_NG4_PLAN, payload: { plans: updatedPlansWithPlot, role } });
        }

        if (UserUtils.isNG4()) {
          const shortFlowPlansWithUpdatedPlot = store
            .getState()
            .myPlans.data.raw.filter(
              (plan) => plan.flow === 'short' && plan.plot.id === updatedPlot.id,
            );
          if (shortFlowPlansWithUpdatedPlot.length > 0) {
            const updatedShortFlowPLansWithPlot = getUpdatedPlansWithPlotNG4(
              shortFlowPlansWithUpdatedPlot,
              updatedPlot,
            );
            dispatch({
              type: MERGE_NG4_PLAN,
              payload: { plans: updatedShortFlowPLansWithPlot, role },
            });
          }
        }

        if (res.json.updatedCropRotations) {
          dispatch({ type: RESET_MY_CROP_ROTATIONS });
        }
        if ((!res.json.updatedCropRotations && !res.json.updatedPlans) || forceCallSuccessClosure) {
          successClosure();
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: POST_PLOT_FAILED, payload: error });
      });
  };

export const getMyCropRotations = (id_token, locale) => (dispatch) => {
  dispatch({ type: MY_CROP_ROTATIONS_LOADING });
  let url = getRestResource('/cropRotation');
  if (UserUtils.isNG4()) {
    url += `&locale=${locale}`;
  }
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      dispatch({ type: MY_CROP_ROTATIONS_SUCCESS_NG4, payload: res.json });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: MY_CROP_ROTATIONS_FAILED, payload: error });
    });
};

export const deleteCropRotation = (id_token, cropRotationId) => (dispatch) => {
  dispatch({ type: DELETE_MY_CROP_ROTATION_LOADING });
  const url = getRestResource(`/cropRotation/${cropRotationId}`);
  webcallPromiseWrapper(url, 'DELETE', id_token)
    .then(() => dispatch({ type: DELETE_MY_CROP_ROTATION_SUCCESS, payload: cropRotationId }))
    .catch((error) => {
      console.error(error);
      dispatch({ type: DELETE_MY_CROP_ROTATION_FAILED, payload: error });
    });
};

export const postCropRotation = (id_token, locale, cropRotation, successCallback) => (dispatch) => {
  dispatch({ type: POST_MY_CROP_ROTATION_LOADING });
  let url = getRestResource('/cropRotation');
  if (UserUtils.isNG4()) {
    url += `&locale=${locale}`;
  }
  webcallPromiseWrapper(url, 'POST', id_token, cropRotation)
    .then((res) => {
      dispatch({ type: POST_MY_CROP_ROTATION_SUCCESS_NG4, payload: res.json });
      successCallback(res.json);
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_MY_CROP_ROTATION_FAILED, payload: error });
    });
};

export const patchCropRotation =
  (id_token, locale, cropRotation, cropRotationId, successCallback) => (dispatch) => {
    dispatch({ type: POST_MY_CROP_ROTATION_LOADING });
    let url = getRestResource(`/cropRotation/${cropRotationId}`);
    if (UserUtils.isNG4()) {
      url += `&locale=${locale}`;
    }
    webcallPromiseWrapper(url, 'PATCH', id_token, cropRotation)
      .then((res) => {
        dispatch({ type: POST_MY_CROP_ROTATION_SUCCESS_NG4, payload: res.json });
        successCallback(res.json);
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: POST_MY_CROP_ROTATION_FAILED, payload: error });
      });
  };

export const inviteReferer = (id_token, invitation) => (dispatch) => {
  dispatch({ type: POST_REFERALS_LOADING });
  const url = getRestResource('/referral');
  webcallPromiseWrapper(url, 'POST', id_token, invitation)
    .then((res) => {
      dispatch({
        type: POST_REFERALS_SUCCESS,
        payload: res.json,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_REFERALS_FAILED, payload: error });
    });
};

export const getReferers = (id_token) => (dispatch) => {
  dispatch({ type: GET_REFERALS_LOADING });
  const url = getRestResource('/referral');
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      dispatch({
        type: GET_REFERALS_SUCCESS,
        payload: res.json,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_REFERALS_FAILED, payload: error });
    });
};

export const subscribeToNewsletter = (subscription, locale) =>
  new Promise((resolve, reject) => {
    fetch(`${borealisPageUrl()}api/subscribe/${locale}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(subscription),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then(({ message }) => {
            resolve(message);
          });
          return;
        }
        res.json().then(({ message }) => {
          reject(message);
        });
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });

export const getCampaign = (locale, campaignType) =>
  new Promise((resolve, reject) => {
    fetch(`${borealisPageUrl()}api/templateData/${locale}/${campaignType}`, {
      method: 'GET',
    })
      .then((res) => res.json().then((result) => resolve(result)))
      .catch(() => reject());
  });

export const getTerrazoFields = (id_token) => (dispatch) => {
  dispatch({ type: GET_TERRAZO_FIELDS_LOADING });
  const url = getRestResource('/appMap/');
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      dispatch({
        type: GET_TERRAZO_FIELDS_SUCCESS,
        payload: res.json,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: GET_TERRAZO_FIELDS_FAILED, payload: error });
    });
};

export const createTerrazoField = (id_token, plotData) => (dispatch) => {
  dispatch({ type: POST_TERRAZO_FIELDS_LOADING });
  const url = getRestResource('/appMap/');
  webcallPromiseWrapper(url, 'POST', id_token, plotData)
    .then((res) => {
      dispatch({
        type: POST_TERRAZO_FIELDS_SUCCESS,
        payload: res.json,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_TERRAZO_FIELDS_FAILED, payload: error });
    });
};

export const updateTerrazoField = (id_token, terrazoMapId, plotData, success) => (dispatch) => {
  dispatch({ type: PATCH_TERRAZO_FIELDS_LOADING });
  const url = getRestResource(`/appMap/${terrazoMapId}`);
  webcallPromiseWrapper(url, 'PATCH', id_token, plotData)
    .then((res) => {
      dispatch({
        type: PATCH_TERRAZO_FIELDS_SUCCESS,
        payload: res.json,
      });
      if (success) {
        success();
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: PATCH_TERRAZO_FIELDS_FAILED, payload: error });
    });
};

export const deleteTerrazoField = (id_token, terrazoMapId, success, fail) => (dispatch) => {
  dispatch({ type: DELETE_TERRAZO_FIELDS_LOADING });
  const url = getRestResource(`/appMap/${terrazoMapId}`);
  webcallPromiseWrapper(url, 'DELETE', id_token)
    .then(() => {
      dispatch({
        type: DELETE_TERRAZO_FIELDS_SUCCESS,
        payload: terrazoMapId,
      });
      success();
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: DELETE_TERRAZO_FIELDS_FAILED, payload: error });
      fail();
    });
};

export const deleteTerrazoFieldForPlotOrPlan =
  (id_token, id, applicationMapIds, type, success, fail) => (dispatch) => {
    dispatch({ type: DELETE_TERRAZO_FIELDS_FOR_RESOURCE_LOADING });
    const queryParam = type === 'plot' ? 'plotId' : 'planId';
    let url = getRestResource('/appMap');
    url += `&${queryParam}=${id}`;
    webcallPromiseWrapper(url, 'DELETE', id_token)
      .then(() => {
        dispatch({
          type: DELETE_TERRAZO_FIELDS_FOR_RESOURCE_SUCCESS,
          payload: applicationMapIds,
        });

        // Needed to wait for redux state updates
        setTimeout(() => {
          success();
        }, 0);
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: DELETE_TERRAZO_FIELDS_FOR_RESOURCE_FAILED, payload: error });
        fail(error);
      });
  };

export const getTerrazoMapInfo = (id_token, terrazoMapId, startDate, endDate, success, error) => {
  let url = getRestResource(`/appMap/${terrazoMapId}`);
  url += `&type=time-entry&start-date=${startDate}&end-date=${endDate}`;
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      success(res.json);
    })
    .catch((err) => {
      console.error(err);
      error(err);
    });
};

export const getTerrazoMapGeoJson = (
  id_token,
  terrazoMapId,
  timeEntryId,
  nrOfZones,
  zoningMode,
  averageN,
  isHumid,
  isQualityGrain,
  success,
  error,
) => {
  let url = getRestResource(`/appMap/${terrazoMapId}`);
  url += `&type=fertmap&time-entry-id=${timeEntryId}&nrOfZones=${nrOfZones}&averageFertilizerRate=${averageN}&zoningMode=${zoningMode}`;
  if (isHumid !== undefined) {
    url += `&isHumid=${isHumid}`;
  }
  if (isQualityGrain !== undefined) {
    url += `&isQualityGrain=${isQualityGrain}`;
  }
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      success({
        res: res.json,
        shouldApplyAverageN: false,
      });
    })
    .catch((err) => {
      if (err.errorCode === 30 && err.errorMessage === AVERAGE_FERT_RATE_VALUE_ERROR) {
        let url = getRestResource(`/appMap/${terrazoMapId}`);
        url += `&type=fertmap&time-entry-id=${timeEntryId}&nrOfZones=${nrOfZones}&averageFertilizerRate=80&zoningMode=${zoningMode}`;
        if (isHumid) {
          url += `&isHumid=${isHumid}`;
        }
        if (isQualityGrain !== undefined) {
          url += `&isQualityGrain=${isQualityGrain}`;
        }
        webcallPromiseWrapper(url, 'GET', id_token)
          .then((res) => {
            success({
              res: res.json,
              shouldApplyAverageN: true,
            });
          })
          .catch((err) => {
            console.error(err);
            error(err);
          });
        return;
      }
      error(err);
    });
};

export const getTerrazoMapVegetationZones = (
  id_token,
  terrazoMapId,
  timeEntryId,
  success,
  error,
) => {
  let url = getRestResource(`/appMap/${terrazoMapId}`);
  url += `&type=geojson&time-entry-id=${timeEntryId}&geojson_type=NDVI`;
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      success(res.json);
    })
    .catch((err) => {
      console.error(err);
      error(err);
    });
};

export const getTerrazoMapImage = (id_token, terrazoMapId, timeEntryId, success, error) => {
  let url = getRestResource(`/appMap/${terrazoMapId}`);
  url += `&time-entry-id=${timeEntryId}&type=geojson&geojson_type=SATELLITE`;
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      success(res.json);
    })
    .catch((err) => {
      console.error(err);
      error(err);
    });
};

export const getTerrazoMapShapeFile = (id_token, terrazoMapId, success, error) => {
  let url = getRestResource(`/appMap/${terrazoMapId}`);
  url += '&type=shapefile';
  webcallPromiseWrapper(url, 'GET', id_token)
    .then((res) => {
      success(res.json);
    })
    .catch((err) => {
      console.error(err);
      error(err);
    });
};

export const sendTerrazoMapShapeFileToEmail = (id_token, terrazoMapId, success, error) => {
  const url = getRestResource(`/appMap/${terrazoMapId}/email`);
  webcallPromiseWrapper(url, 'GET', id_token)
    .then(() => {
      success();
    })
    .catch((err) => {
      console.error(err);
      error(err);
    });
};

export const bulkDeletePlots = (id_token, plotIds, success) => async (dispatch) => {
  dispatch({ type: DELETE_MY_PLOT_BULK_LOADING });
  plotIds.forEach((plotId) => {
    const url = getRestResource(`/plot/${plotId}`);
    const webService = getWebServiceCallForBulkOperation(plotId, url, 'DELETE', id_token);
    const process = new Process(webService);
    ProcessQueue.shared().addProcess(process);
  });
  const { successIds, failureIds } = await ProcessQueue.shared().executeProcesses();
  dispatch({
    type: DELETE_MY_PLOT_BULK_SUCCESS,
    payload: successIds,
  });
  success(successIds, failureIds);
};

export const bulkCreatePlans = (id_token, role, plans, success) => async (dispatch) => {
  dispatch({ type: POST_PLAN_BULK_LOADING });
  const url = getUrl('genericPlan');
  webcallPromiseWrapper(url, 'POST', id_token, { plans })
    .then((result) => {
      createVirtualPageView(GTMEventTypes.PLAN_CREATED);
      dispatch({ type: POST_PLAN_BULK_SUCCESS });
      dispatch({
        type: MERGE_NG4_PLAN,
        payload: { plans: result.json.plans ? result.json.plans : [result.json], role },
      });
      success();
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: POST_PLAN_BULK_FAILED, payload: error });
    });
};
