import {
    farmdokDEMockAppleMobileAnimation,
    landingPageVideo1DE,
    landingPageVideo2DE,
    landingPageVideo1DEWebP,
    landingPageVideo2DEWebP,
} from 'assets/images';
import {
    XMLInstructions,
    Terms_and_conditions_DE,
    Privacy_Notice_DE,
    NutriGuide_user_manual_DE_LAT,
} from 'assets/pdf';
import PhoneAT from 'components/Icons/phones/PhoneAT';

// MARK: - LocaleDependentBehaviours

export const de_DE = {
    borealis_lat_localization: '/de/de/',
    farmdok_mock_apple_mobile: farmdokDEMockAppleMobileAnimation,
    farmdok_mock_apple_mobile_webp: null,
    terms_conditions_pdf: Terms_and_conditions_DE,
    privacy_notice_pdf: Privacy_Notice_DE,
    number_locales_code: 'de-DE',
    xml_upload_instructions_pdf: false,
    user_manual_pdf: NutriGuide_user_manual_DE_LAT,
};

// MARK: - Behaviours

export const DE = {
    is_letters_available_in_postalcode: false,
    intro_youtube_video_id: 'Yl8Wiy6IqFs',
    landing_page_top_video_id: 'v76xfICYJIE',
    postal_code_length: 4,
    grass: false,
    n_mineralization: false,
    cover_crop_details: false,
    fertilizer_period: false,
    fertilizer_lasttime: false,
    soil_type: false,
    work_your_soil: false,
    periodic_el_info: false,
    soil_heaviness: true,
    soil_organic_matter_level: true,
    soil_analysis: true,
    applied_element_year: 0,
    show_applied_element_year: false,
    soil_depth_id: 5,
    add_product: false,
    my_plots_functionalities: true,
    has_options_for_cover_crop_details: true,
    upload_xml: true,
    get_plots_from_3rd_party: false,
    can_select_postal_code_and_city_for_entire_farm_plots: false,
    can_use_map_and_postal_code_for_city_selection: false,
    crop_rotation: true,
    farmer_can_change_product: true, // In NG4 all the users are farmers
    available_crops_id: [1, 2, 4, 10, 11, 5, 6, 7, 8, 9],
    all_crops_presented: true, // Option to present all crop icons at landing page
    only_for_bavaria_region: true,
    only_long_flow_supported: false,
    has_cover_crop_question: true,
    available_crops_v3: [
        {
        name: 'cereal',
        logoId: 'Lp3',
        },
        {
        name: 'rape_seed',
        logoId: 'Lp1',
        },
        {
        name: 'maize',
        logoId: 37,
        },
        {
        name: 'potato',
        logoId: 6,
        },
        {
        name: 'sunflower',
        logoId: 7,
        },
        {
        name: 'sugar_beet',
        logoId: 9,
        },
        {
        name: 'forage_grass',
        logoId: 12,
        },
    ],
    reseller_farmer_video: true,
    has_farmDok_slide: false,
    fachbeirat_logo: true,
    phone: PhoneAT,
    product_category_filter: false,
    spreader_links: {
        sulky: 'http://fertitest.sulky-burel.com/FertiKli/?lng=ge',
        amazone: 'https://amazone.de/78.asp',
        kuhn: 'https://streutabellen.rauch-community.de/kuhntabelle/?lang=49',
        bogballe: 'http://www.bogballe-charts.com/spreader.asp?S=4',
        kverneland: 'https://www.kvernelandspreadingcharts.com',
        vicon: 'https://www.viconspreadingcharts.com/vicon/home/',
    },
    map_options: {
        defaultCenter: {
        lat: 49.29,
        lng: 11.15,
        },
        zoom: 6,
    },
    xml_files_download_link: false,
    fertilizer_needs: true,
    my_applications: true,
    xml_upload_instructions_pdf: XMLInstructions,
    farmdok_mock_apple_mobile: farmdokDEMockAppleMobileAnimation,
    landing_page_video_1: landingPageVideo1DE,
    landing_page_video_1_webp: landingPageVideo1DEWebP,
    landing_page_video_2: landingPageVideo2DE,
    landing_page_video_2_webp: landingPageVideo2DEWebP,
    landing_page_yt_video_1: 'eF1jfolBhsY',
    landing_page_yt_video_2: 'xnyfCJ1ES9Q',
    supported_file_types: '',
    refer_a_farmer_functionality: false,
    promotion_image: null,
    empty_soil: {
        soilFactorP: 1,
        soilFactorK: 1,
        nMineralization: 50,
        nMin: 50,
        hasHumusEffect: false,
        isRedArea: false,
    },
    has_n_min: false,
    soil_class_classification_presented: true,
    only_soil_classification_letters_presented: true,
    application_map_functionality: true,
    application_map_functionality_not_working: false,
    shouldShowChangeFormulae_in_all_steps: true,
    has_nutrizone: true,
    nutrizone_brand_video_id: 'c-a3JZ18ens',
    nutrizone_testimonial_video_id: '-LUTpY26P9o',
    get_postal_code_on_xml_import: true,
    tutorial_videos_link:
        'https://www.youtube.com/playlist?list=PL1HjzobO2od5oPMLssAhDq5CAEzotXAHG',
    nmineralization_humus_option: true,
    has_organic_fertilization_in_the_previous_year_option: true,
    has_red_zone: true,
    has_no_options_for_nMineralization: true,
    eAMA_text: false,
    click_here_text: false,
    want_to_set_N_upper_limit: true,
    try_nutriguide_button_visibility: false,
    modified_long_flow_supported: false,
};
