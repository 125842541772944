import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import userUtils from 'Utils/UserUtils';
import { login } from 'Utils/MSAL_AuthUtils';
import { store } from 'Redux/createStore';

import { PLAN_ID, CROP_ROTATION_ID, FLOW_SELECT, MY_OVERVIEW } from 'constants/RoutesConstants.js';

const OnboardedRouteComponent = ({
  component: Component,
  user,
  path,
  inverse = false,
  render,
  ...rest
}) => {
  const { isLoggedIn } = store.getState().user;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userUtils.isOnboarded(user) !== inverse) {
          if (render) {
            return render();
          }
          return <Component {...props} />;
        }
        if (!isLoggedIn && (path.includes(PLAN_ID) || path.includes(CROP_ROTATION_ID))) {
          return login(MY_OVERVIEW, user.lang);
        }
        return (
          <Redirect to={FLOW_SELECT} />
        ); /* the flow select route will handle the redirect to the correct get-started page if needed */
      }}
    />
  );
};

OnboardedRouteComponent.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  user: PropTypes.object,
  inverse: PropTypes.bool,
  render: PropTypes.func,
  path: PropTypes.any,
};

export default OnboardedRouteComponent;
