/* eslint-disable import/prefer-default-export */
export const supportedCountries = [
  {
    code: 'AT',
    languages: ['DE', 'EN'],
  },
  {
    code: 'FR',
    languages: ['FR', 'EN'],
  },
  {
    code: 'RO',
    languages: ['RO', 'EN'],
  },
  {
    code: 'HU',
    languages: ['HU', 'EN'],
  },
  {
    code: 'BG',
    languages: ['BG', 'EN'],
  },
  {
    code: 'DE',
    languages: ['DE', 'EN'],
  },
  {
    code: 'UK',
    languages: ['EN'],
  },
];
