import React from 'react';
import { groupBy, map, uniq, compact, includes } from 'lodash';
import SanitizedHTML from 'react-sanitized-html';
import { format } from 'date-fns';
import { de, fr, hu, ro, bg } from 'date-fns/locale';
import UserUtils from 'Utils/UserUtils';

import { localized } from 'data/Localization';
import DateHelper from '../helpers/DateHelper';

export const adjustData = (data) => {
  let adjustedData = groupBy(data, (product) => product.nutrientCategory);

  adjustedData = Object.keys(adjustedData).map((key) => ({
    sectionName: key,
    products: adjustedData[key],
  }));

  adjustedData = adjustedData.sort((a, b) => {
    if (a.sectionName < b.sectionName) return -1;
    if (a.sectionName > b.sectionName) return 1;
    return 0;
  });

  adjustedData = adjustedData.map((section) => {
    // eslint-disable-next-line no-param-reassign
    section.products = section.products.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return section;
  });

  return adjustedData;
};

export const applySelectedFiltersOnData = (data, productFilters) => {
  if (productFilters.length > 0) {
    const groupedFilters = groupBy(productFilters, 'categoryLabel');
    return data.filter((product) => {
      let shouldReturnProduct = true;
      Object.keys(groupedFilters).forEach((key) => {
        let atLeastOneFilterOfCategoryApplies = false;
        groupedFilters[key].forEach(({ value }) => {
          if (product[key] === value) atLeastOneFilterOfCategoryApplies = true;
        });
        if (!atLeastOneFilterOfCategoryApplies) shouldReturnProduct = false;
      });
      return shouldReturnProduct;
    });
  }
  return data;
};

export const calculateFilterData = (data) => {
  const getFilter = (key) => {
    let filters = compact(uniq(map(data, key)));
    filters = filters.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    return filters;
  };
  const types = getFilter('type');
  const nutrientCategories = getFilter('nutrientCategory');
  const categories = getFilter('category');
  return { types, nutrientCategories, categories };
};

export const transformFilterDisplayName = (filter, category, locale) => {
  if (category === 'nutrientCategory') {
    return filter;
  }
  return localized(locale, filter);
};

export const removeNotApplicableFilters = (productFilters, data) =>
  productFilters.filter((filter) => {
    let applicableFilter = false;
    data.forEach((product) => {
      if (includes(product, filter.value)) applicableFilter = true;
    });
    return applicableFilter;
  });

export const generateProductName = (product, locale) => {
  const { type, name, category, n, p, k, s } = product;

  // NG4 has only Borealis products, we don't have a type key on the product now
  if (UserUtils.isNG4()) {
    return name;
  }
  if (type === 'BOREALIS') {
    return name;
  }
  if (type === 'RESELLER') {
    let resellerProductName = `${localized(locale, category)} ${n}/${p}`;

    if (k) resellerProductName += `/${k}`;

    if (s) resellerProductName += ` + ${s} SO<sub>3</sub>`;
    return <SanitizedHTML html={resellerProductName} allowedTags={['sub']} />;
  }
  return null;
};

export const defineProductPages = (match) => {
  const { path } = match;
  if (path.includes('/product-select')) {
    return [
      {
        name: 'select',
        active: true,
      },
      {
        name: 'portfolio',
        active: false,
      },
    ];
  }
  return [
    {
      name: 'select',
      active: false,
    },
    {
      name: 'portfolio',
      active: true,
    },
  ];
};

export const formatLegalDeadline = (legalDeadline, lang) => {
  if (!legalDeadline || legalDeadline.includes('undefined')) {
    return '';
  }
  const [from, to] = legalDeadline.split(' - ');
  const languages = {
    FR: fr,
    DE: de,
    HU: hu,
    RO: ro,
    BG: bg,
  };
  if (!to) {
    return format(new Date(from), 'd MMMM yyyy', {
      locale: languages[lang],
      awareOfUnicodeTokens: true,
    });
  }

  const formattedFrom = format(
    new Date(DateHelper.shared.getNormalizedDateString(`2019-${from}`)),
    'd MMMM',
    { locale: languages[lang], awareOfUnicodeTokens: true },
  );
  const formattedTo = format(
    new Date(DateHelper.shared.getNormalizedDateString(`2019-${to}`)),
    'd MMMM',
    { locale: languages[lang], awareOfUnicodeTokens: true },
  );

  // On the frontend is presented when the user can apply fertilizer
  return `${formattedTo} - ${formattedFrom}`;
};

export const updateProductFilter = (
  checked,
  value,
  productFilters,
  updateProductFilters,
  categoryLabel,
  match,
) => {
  const pages = defineProductPages(match);
  const newProductFilters = {};
  pages.forEach(({ name, active }) => {
    if (!active) newProductFilters[name] = productFilters[name];
    else {
      const selectedFilter = { categoryLabel, value };
      if (checked) {
        newProductFilters[name] = [...productFilters[name], selectedFilter];
      } else {
        newProductFilters[name] = productFilters[name].filter(
          (productFilter) => productFilter.value !== selectedFilter.value,
        );
      }
    }
  });
  updateProductFilters(newProductFilters);
};
