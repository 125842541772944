/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import testCropTest from 'data/generic_crops_test.json';

const BASE_URL = 'https://nutriguide-python-prod.azurewebsites.net/api';
const API_KEY = 'fmcNIGEZ5vbDZOx2aCreUH7XKEsmh4qCCRBV4DaH9hSiK61qXaz7Qw==';

export const GTM_HEAD =
  '<!-- Google Tag Manager -->\n' +
  "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
  "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
  "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
  "'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=Z4cEtwXBf3CX8ie-RcwGeQ&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);\n" +
  "})(window,document,'script','dataLayer','GTM-TZRFSTD');</script>\n" +
  '<!-- End Google Tag Manager -->';

export const GTM_BODY =
  '<!-- Google Tag Manager (noscript) -->\n' +
  '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZRFSTD&gtm_auth=Z4cEtwXBf3CX8ie-RcwGeQ&gtm_preview=env-2&gtm_cookies_win=x"\n' +
  'height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>\n' +
  '<!-- End Google Tag Manager (noscript) -->';

export const FACEBOOK_PIXEL_SCRIPT = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '4329670170473206');
  fbq('track', 'PageView');`;

export const FACEBOOK_PIXEL_NOSCRIPT =
  '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=4329670170473206&ev=PageView&noscript=1" />';

const AZURE_ENDPOINTS = {
  user: '/user',
  cropList: '/cropList',
  organicFertilizerList: '/organicfertilizerList',
  soilList: '/soilList',
  soilComposition: '/soilcomposition',
  postPlan: '/plan',
  planList: '/planList',
  deletePlan: '/plan',
  cityList: '/cityList',
  getTerms: '/terms',
  getOrganisation: '/organisation',
  generic: '/generic/test/',
  genericCropList: '/generic/crop',
  genericOrganicFertilizerList: '/generic/organicFertilizer',
  genericOrganicFertilizer: '/generic/organicFertilizer',
  genericPlan: '/generic/plan',
};

export const AZURE_AD_B2C_CONFIG = {
  clientID: '430dd619-c607-4993-abb8-813c8b392119',
  signInUp_authority:
    'https://ntrgrpb2c.b2clogin.com/ntrgrpb2c.onmicrosoft.com/B2C_1_PRD_Nutriguide_SiUpIn_modern',
  resetPassword_authority:
    'https://ntrgrpb2c.b2clogin.com/ntrgrpb2c.onmicrosoft.com/B2C_1_PRD_Nutriguide_SSPR_modern',
};

const GOOGLE_MAPS_API_KEY = 'AIzaSyCUyRahz_DyrMGcOdHP6LV_dxwJ-nf0aGU';

function encodeQueryData(data) {
  const ret = [];
  for (const d in data) ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
  return ret.join('&');
}

function createEnvURL(endpoint) {
  return BASE_URL + AZURE_ENDPOINTS[endpoint];
}

export const getUrl = (endpoint, input, pathComponents) => {
  let url = createEnvURL(endpoint);
  if (pathComponents) {
    url += pathComponents;
  }
  url += `?code=${API_KEY}`;
  if (input && endpoint !== 'deletePlan') {
    url += `&${encodeQueryData(input)}`;
  }
  return url;
};

export const getRestResource = (route, input) => {
  let url = BASE_URL;
  url += route;
  url += `?code=${API_KEY}`;
  if (input) url += `&${encodeQueryData(input)}`;
  return url;
};

export const googleMapApiKey = () => GOOGLE_MAPS_API_KEY;
export const borealisPageUrl = () => 'https://www.lat-nitrogen.com/';
export const borealisImageStore = () =>
  'https://latwebprodstorage.blob.core.windows.net/blobcloudstorage/';

export const getGTMHead = () => GTM_HEAD;
export const getGTMBody = () => GTM_BODY;
export const getFacebookPixelScript = () => FACEBOOK_PIXEL_SCRIPT;
export const getFacebookPixelNoScript = () => FACEBOOK_PIXEL_NOSCRIPT;

const DATA_ENVIRONMENT_PKCALCULATOR = 'pkcaculator';

const DATA_ENVIRONMENT = DATA_ENVIRONMENT_PKCALCULATOR;
export const isLive = () => DATA_ENVIRONMENT === DATA_ENVIRONMENT_PKCALCULATOR;
export const getTestCropData = () => testCropTest;
