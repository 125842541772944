/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-underscore-dangle */
import * as Msal from 'msal';
import { store } from 'Redux/createStore';
import { getUser, updateUser, updatePlan } from 'Redux/actions';
import { RESET_USER, RESET_MY_PLOTS, RESET_TERMS } from 'Redux/actions/types';
import { AZURE_AD_B2C_CONFIG } from 'data/Config';

export let UserAgentApplication = null;

export const resetPassword = (lang) => {
  store.dispatch(updateUser({ authenticating: true }));

  const userAgentApplicationFP = new Msal.UserAgentApplication(
    AZURE_AD_B2C_CONFIG.clientID,
    AZURE_AD_B2C_CONFIG.resetPassword_authority,
    () => {},
    {
      postLogoutRedirectUri: window.location.origin,
      validateAuthority: false,
    },
  );
  userAgentApplicationFP._redirectUri = UserAgentApplication
    ? UserAgentApplication._redirectUri
    : window.location.origin;

  let extraQueryParams;
  if (lang) {
    extraQueryParams = `ui_locales=${lang}`;
  }
  userAgentApplicationFP.loginRedirect(undefined, extraQueryParams);
};

export const handleUser = (errorDesc, token, error, tokenType, store) => {
  store.dispatch(updateUser({ [tokenType]: token }));

  switch (tokenType) {
    case 'id_token': {
      getUser(store.dispatch, token);
      break;
    }
    default:
  }
};

export const initUserAgentApplication = (lang) => {
  UserAgentApplication = new Msal.UserAgentApplication(
    AZURE_AD_B2C_CONFIG.clientID,
    AZURE_AD_B2C_CONFIG.signInUp_authority,
    (errorDesc, token, error, tokenType) => {
      if (token) {
        store.dispatch(updateUser({ isLoggedIn: true }));
      }
      if (errorDesc && errorDesc.includes('AADB2C90118')) {
        resetPassword(lang);
      } else if (errorDesc && errorDesc.includes('AADB2C90091')) {
        store.dispatch(updatePlan({ shouldPost: false }));
      } else {
        handleUser(errorDesc, token, error, tokenType, store);
      }
    },
    {
      cacheLocation: 'localStorage',
      postLogoutRedirectUri: window.location.origin,
      validateAuthority: false,
    },
  );
};

export const login = (redirectUri, lang) => {
  store.dispatch(updateUser({ authenticating: true }));
  UserAgentApplication._redirectUri = redirectUri;
  let extraQueryParams;

  if (lang) {
    extraQueryParams = `ui_locales=${lang}`;
  }

  UserAgentApplication.loginRedirect(undefined, extraQueryParams);
};

export const logout = () => {
  UserAgentApplication.logout();
  store.dispatch({ type: RESET_MY_PLOTS });
  store.dispatch({ type: RESET_USER });
  store.dispatch({ type: RESET_TERMS });
  store.dispatch(updateUser({ loggingOut: true }));
  localStorage.clear();
};

export const getToken = () => {
  UserAgentApplication._redirectUri = window.location.origin;
  return UserAgentApplication.acquireTokenSilent([AZURE_AD_B2C_CONFIG.clientID]).then(
    (accessToken) => accessToken,
    () => {
      store.dispatch({ type: RESET_MY_PLOTS });
      store.dispatch({ type: RESET_USER });
      store.dispatch(updateUser({ authenticating: true }));
      localStorage.clear();
      return UserAgentApplication.loginRedirect();
    },
  );
};
