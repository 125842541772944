/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable quotes, quote-props, import/prefer-default-export */
import React, { useContext } from 'react';
import { UserContext } from 'context';
import English from 'data/Nutriguide_English.json';
import French from 'data/Nutriguide_French.json';
import German from 'data/Nutriguide_German.json';
import Hungarian from 'data/Nutriguide_Hungarian.json';
import Romanian from 'data/Nutriguide_Romanian.json';
import Bulgarian from 'data/Nutriguide_Bulgarian.json';
import German_de from 'data/Nutriguide_German_de';

export const localized = (locale, label, role) => {
  if (role) {
    if (localizations[locale][`${label}--${role}`]) {
      label = `${label}--${role}`;
    }
  }

  const localization = localizations[locale][label];
  if (localization === undefined) {
    console.error(`error getting string for locale:${locale} lab:${label}`);
    return `labels error ${locale} ${label}`;
  }

  return localization;
};

export const localizedUnit = (locale, unit) => {
  const translations = {
    'kg/ha': localized(locale, 'unit_kgha'),
    't ': localized(locale, 'unit_tha'),
    t: localized(locale, 'unit_tha'),
    m3: localized(locale, 'unit_m3solidha'),
    't/ha': localized(locale, 'unit_tha'),
  };
  return translations[unit];
};

export const localizedFromContext = (label) => (
  <UserContext.Consumer>
    {({ locale, role }) => localized(locale, label, role)}
  </UserContext.Consumer>
);

export const useLocalizedFromContext = (label) => {
  const { locale, role } = useContext(UserContext);
  return localized(locale, label, role);
};

const localizations = {
  fr_FR: French,
  de_DE: German_de,
  de_AT: German,
  hu_HU: Hungarian,
  ro_RO: Romanian,
  bg_BG: Bulgarian,
  en_UK: English,

  en_FR: English,
  en_DE: English,
  en_AT: English,
  en_HU: English,
  en_RO: English,
  en_BG: English,
};
